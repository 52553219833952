import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

const _Modal = ({ open, setOpen, className, children }, ref) => (
    <Transition.Root show={open} as={Fragment}>
        <Dialog
            as="div"
            static
            className="fixed z-50 inset-0 overflow-y-auto"
            onClose={setOpen}
            initialFocus={ref}
        >
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 text-center sm:block sm:p-0">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity print:hidden" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        className={`inline-block align-bottom bg-white rounded-lg px-4 py-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle md:min-w-0 md:max-w-sm print:hidden sm:p-6 ${className}`}
                    >
                        {children}
                    </div>
                </Transition.Child>
            </div>
        </Dialog>
    </Transition.Root>
);

const Modal = React.forwardRef(_Modal);
export default Modal;
