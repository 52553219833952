import React, { useEffect, useRef } from "react";
import { InputError } from "./InputError";

const Input = ({ left, middle, right, onChange, onKeyDown, onPaste, inputRef }) => {
    const containerStyles = middle || right ? "-ml-px" : "";
    let roundedStyles = "rounded-none ";
    if (left) {
        roundedStyles += " rounded-bl-md rounded-tl-md";
    }
    if (right) {
        roundedStyles += " rounded-tr-md rounded-br-md";
    }
    return (
        <div className={containerStyles}>
            <input
                style={{ width: "40px" }}
                maxLength="1"
                ref={inputRef}
                onKeyDown={onKeyDown}
                onChange={onChange}
                onPaste={onPaste}
                className={`form-input bg-gray-100 mr-1 border border-transparent focus:border-gray-500 focus:bg-white focus:outline-none text-center uppercase relative block ${roundedStyles} focus:z-10 transition ease-in-out duration-150 text-2xl`}
            />
        </div>
    );
};

const OTCInput = ({ error, onChange }) => {

    const refs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    useEffect(() => {
        setTimeout(() => {
            refs[0].current.focus();
        }, 100);
    }, [refs]);

    const onInputChange = (id) => {
        if (refs[id].current.value !== "") {
            if (id + 1 <= refs.length - 1) {
                refs[id + 1].current.focus();
                refs[id + 1].current.select();
            }
            const value = refs.map((r) => r.current.value);
            value.splice(3, 0, "-");
            onChange(value.join(""));
        }
    };

    const onKeyDown = (id, event) => {
        if (event.keyCode === 8 && refs[id].current.value === "") {
            if (id - 1 <= refs.length - 1) {
                refs[id - 1].current.focus();
                refs[id - 1].current.select();
            }
        }
    };

    return (
        <fieldset>
            <div className="flex justify-center items-center font-mono">
                <div className="mt-1 bg-white rounded-md shadow-sm">
                    <div className="-mt-px flex">
                        <Input
                            inputRef={refs[0]}
                            onKeyDown={(event) => onKeyDown(0, event)}
                            onChange={(event) => onInputChange(0, event)}
                            onPaste={(event) => {
                                event.preventDefault();
                                let pasteRaw = (event.clipboardData || window.clipboardData).getData('text');
                                let paste = pasteRaw.split("").filter(c => c !== "-");
                                if (paste.length === 6) {
                                    paste.map((c, i) => refs[i].current.value = c);
                                    onChange(pasteRaw);
                                }
                            }}
                            left
                        />
                        <Input
                            inputRef={refs[1]}
                            onKeyDown={(event) => onKeyDown(1, event)}
                            onChange={(event) => onInputChange(1, event)}
                            middle
                        />
                        <Input
                            inputRef={refs[2]}
                            onKeyDown={(event) => onKeyDown(2, event)}
                            onChange={(event) => onInputChange(2, event)}
                            right
                        />
                    </div>
                </div>
                <div className="text-gray-700 text-2xl px-3">-</div>
                <div className="mt-1 bg-white rounded-md shadow-sm">
                    <div className="-mt-px flex">
                        <Input
                            inputRef={refs[3]}
                            onKeyDown={(event) => onKeyDown(3, event)}
                            onChange={(event) => onInputChange(3, event)}
                            left
                        />
                        <Input
                            inputRef={refs[4]}
                            onKeyDown={(event) => onKeyDown(4, event)}
                            onChange={(event) => onInputChange(4, event)}
                            middle
                        />
                        <Input
                            inputRef={refs[5]}
                            onKeyDown={(event) => onKeyDown(5, event)}
                            onChange={(event) => onInputChange(5, event)}
                            right
                        />
                    </div>
                </div>
            </div>
            {error && (
                <div className="flex justify-center mt-4">
                    <InputError error={error} />
                </div>
            )}
        </fieldset>
    );
};

export default OTCInput;
