import { Dialog } from "@headlessui/react";
import { useRouter } from "next/router";
import Link from "next/link";
import React, { useEffect, useRef, useState } from "react";
import { authService, customersService } from "../api/index";
import Logo from "../public/logo.svg";
import { PrimaryButton } from "./forms/Button";
import { Input } from "./forms/Input";
import OTCInput from "./forms/OTCInput";
import Modal from "./Modal";


export default function LoginModal({ open, setOpen }) {
    const Customer = customersService();
    const Auth = authService();
    const router = useRouter();
    let initialFocus = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [requestCodeOpen, setRequestCodeOpen] = useState(false);
    const [token, setToken] = useState(null);

    const [emailAddress, setEmailAddress] = useState("");
    const [emailAddressError, setEmailAddressError] = useState("");

    const [form, setForm] = useState({ login_request: "", auth_code: "" });
    const [formErrors, setFormErrors] = useState({
        login_request: "",
    });

    const { profile } = Customer.useMe();

    // If me becomes a valid customers, redirect to the recipes page.
    // This could be because:
    //  - A user landed on login with a valid api-token
    //  - A user has exchanged credentials for an api-token in this page.
    useEffect(() => {
        if (open && profile !== undefined && profile.username) {
            router.push("/library").then(() => window.scrollTo(0, 0));
        }
    }, [profile, open]);

    // Once a user has exchanged credentials for a token, set it on localStorage
    // and mutate /me so that we determine if the token is valid.
    useEffect(() => {
        if (token !== null) {
            localStorage.setItem("apiToken", token);
            Customer.mutateMe();
        }
    }, [token]);

    const requestLogin = async (event) => {
        event.preventDefault();
        const { data, status } = await Auth.loginRequest({
            email: emailAddress,
        });
        setIsLoading(true);
        switch (status) {
            case 200:
                setRequestCodeOpen(true);
                setForm({ ...form, login_request: data.login_request });
                break;
            default:
                setEmailAddressError(data.email || data.non_field_errors);
        }
        setIsLoading(false);
    };

    const login = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        const { data, status } = await Auth.login(form);
        switch (status) {
            case 200:
                setToken(data.token);
                break;
            default:
                setFormErrors(data);
                setIsLoading(false);
        }
    };

    const { auth_code: authCodeErrors } = formErrors;

    return (
        <Modal open={open} setOpen={setOpen} ref={initialFocus}>
            <div className="mx-auto flex items-center justify-center">
                <Logo className="h-16 w-auto text-gray-900 fill-current" />
            </div>
            <div className="mt-3 text-center sm:mt-5">
                <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                >
                    {requestCodeOpen
                        ? "Check your email for a code"
                        : "Sign in to your account"}
                </Dialog.Title>
                <div className="mt-2">
                    <p className="text-sm text-gray-500">
                        {requestCodeOpen
                            ? `We’ve sent a six-digit code to ${emailAddress}. The code will expire shortly, so please enter it soon.`
                            : "We need the email address you registered with us"}
                    </p>
                </div>
                <p className="mt-2 text-sm text-gray-500">
                    You don&apos;t have an account?{" "}
                    <a href="/join" className="font-semibold text-gray-900">
                        Get started
                    </a>
                </p>
            </div>
            <form
                onSubmit={requestCodeOpen ? login : requestLogin}
                className="flex flex-col space-y-6 pt-4"
            >
                {requestCodeOpen ? (
                    <>
                        <OTCInput
                            onChange={(v) => setForm({ ...form, auth_code: v })}
                            error={authCodeErrors}
                        />
                        <PrimaryButton
                            size="xl"
                            type="submit"
                            loading={isLoading}
                            onClick={login}
                            className="w-full flex justify-center"
                        >
                            Sign in
                        </PrimaryButton>
                    </>
                ) : (
                    <>
                        <Input
                            id="email"
                            placeholder="your@email.com"
                            error={emailAddressError}
                            name="email"
                            value={emailAddress}
                            ref={initialFocus}
                            onChange={(e) => setEmailAddress(e.target.value)}
                            label="Email"
                            autoComplete="on"
                        />
                        <PrimaryButton
                            size="xl"
                            type="submit"
                            loading={isLoading}
                            onClick={requestLogin}
                            className="w-full inline-flex justify-center mt-4"
                        >
                            Continue with email
                        </PrimaryButton>
                    </>
                )}

                <p className="text-center text-xs text-gray-500 mt-4">
                    If you are having trouble to login, please{" "}
                    <a
                        className="font-semibold"
                        href="mailto:support@fork.club"
                    >
                        contact us
                    </a>
                </p>
            </form>
        </Modal>
    );
}
